<template>
  <div :class="{xl3: isExportable, xs12: isExportable, md6: isExportable}" class="flex">

    <vac-card
      v-if="isExportable"
      :title="$t('Browsers')"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="Browsers"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions
        v-if="isExportable"
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />

      <Doughnut
        :dataset-id-key="'label'"
        :height="500"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'doughnut export'"
        :style="{height: '500px'}"
        class="browser"
      />

<!--      <div v-for="(_, key) in data['labels']" :key="key" class="labels">-->
<!--        <div v-if="key % 2 === 0" class="labels__wrapper">-->
<!--          <div class="labels__item">-->
<!--            <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key]}" class="labels__point"/>-->
<!--            {{ data['labels'][key] }}-->
<!--          </div>-->
<!--          <div v-if="data['labels'][key + 1]" class="labels__item">-->
<!--            <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key + 1]}" class="labels__point"/>-->
<!--            {{ data['labels'][key + 1] }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="$t('Browsers')"
      class="chart-widget browser">

      <Doughnut
        :dataset-id-key="'label'"
        :height="400"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'doughnut-small export'"
        :style="{height: '400px'}"
      />

<!--      <div v-for="(_, key) in data['labels']" :key="key" class="labels">-->
<!--        <div v-if="key % 2 === 0" class="labels__wrapper">-->
<!--          <div class="labels__item">-->
<!--            <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key]}" class="labels__point"/>-->
<!--            {{ data['labels'][key] }}-->
<!--          </div>-->
<!--          <div v-if="data['labels'][key + 1]" class="labels__item">-->
<!--            <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key + 1]}" class="labels__point"/>-->
<!--            {{ data['labels'][key + 1] }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </vac-card>

  </div>

</template>

<script>
import VacChart from '../VacChart';
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'Browsers',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },

    showValuesInLabels: {
      type: Boolean,
      default: false,
    },
    isExportable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    // VacChart,
    Actions,
    ExcelPreviewModal,
    Doughnut
  },
  data() {
    return {
      isPreview: false,
      activeSwitcherButton: 1
    }
  },
  computed: {
    chartData() {
      if (this.showValuesInLabels) {
        let formattedLabels = this.data.labels.map((l, index) => {
          return `${l}: ${this.data.datasets[0].data[index]}`
        })
        return {
          datasets: this.data.datasets,
          labels: formattedLabels,
        }
      } else {
        return this.data
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.label}: ${ttItem.parsed}`;
                } else {
                  let sum = 0;
                  let dataArr = ttItem.dataset.data;
                  dataArr.map(data => {
                    sum += Number(data);
                  });
                  let percentage = (ttItem.parsed * 100 / sum).toFixed(2) + '%';
                  return `${ttItem.label}: ${percentage}`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              fontSize: 16,
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          }
        }
      }
    },
    chartOptionsWithoutLabels() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.label}: ${ttItem.parsed}`;
                } else {
                  let sum = 0;
                  let dataArr = ttItem.dataset.data;
                  dataArr.map(data => {
                    sum += Number(data);
                  });

                  let percentage = (ttItem.parsed * 100 / sum).toFixed(2) + '%';
                  return `${ttItem.label}: ${percentage}`;
                }
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    },
    preparedData() {
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }
      const returnObj = {}
      returnObj.labels = ["Browsers", "Count"]
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];
      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            data: [labels[index], info]
          };
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(labels[index]).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info).length)
          returnObj.datasets.push(obj);
        });
      });
      return returnObj;
    }
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Browser", "Count"];
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }

      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            "Browser": labels[index],
            "Count": info,
          };
          customData.push(obj);
        });
      });

      HelperExcel.exportToExcel({}, "Browsers", customLabels, customData);
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
};
</script>

<style lang="scss">
.labels {
  display: flex;
  align-items: center;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: space-around;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  &__point {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 100%;
    display: block;
    margin-right: 5px;
  }
}

.doughnut {
  height: 500px;
}

.doughnut-small {
  height: 400px;
}
</style>
